import React, {useState, useEffect} from "react";
import {Button, Form, Input} from "semantic-ui-react";

const DATE_DISPLAY_OPTIONS = {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"};

export default function RelaysForm(props) {
    const [activityId, setActivityId] = useState("");
    const [jobId, setJobId] = useState("");
    const [airingId, setAiringId] = useState("");
    const [mediaId, setMediaId] = useState("");
    const [action, setAction] = useState("");
    const [status, setStatus] = useState("");
    const [lastUpdated, setLastUpdated] = useState("");

    useEffect(function updateFormFields() {
        if (Object.keys(props.selectedJob).length > 0) {
            setActivityId(props.selectedJob.activityId);
            setJobId(props.selectedJob.jobId);
            setAiringId(props.selectedJob.airingId);
            setMediaId(props.selectedJob.mediaId || "");
            setAction(props.selectedJob.action);
            setStatus(props.selectedJob.status);
            setLastUpdated(props.selectedJob.lastUpdated ?
                props.selectedJob.lastUpdated.toLocaleString("en-US", DATE_DISPLAY_OPTIONS) : "")
        } else {
            setActivityId("");
            setJobId("");
            setAiringId("");
            setAction("");
            setStatus("");
            setLastUpdated("");
        }
    }, [JSON.stringify(props.selectedJob)]);

    return (
        <Form>
            <Button
                onClick={props.reprocessClicked}
                className="synapseButton"
                disabled={jobId.length < 1}> Reprocess </Button>
            <Button
                onClick={props.refreshClicked}
                className="synapseButton">Refresh</Button>
            <br/>
            <br/>
            <br/>
            <Form.Field
                control={Input}
                label="Activity ID"
                readOnly
                value={activityId}
            />
            <Form.Field
                control={Input}
                label="Job ID"
                readOnly
                value={jobId}
            />
            <Form.Field
                control={Input}
                label="Airing ID"
                readOnly
                placeholder="Airing ID"
                value={airingId}
            />
            <Form.Field
                control={Input}
                label="Media ID"
                readOnly
                placeholder="Media ID"
                value={mediaId}
            />
            <Form.Field
                control={Input}
                label="Action"
                readOnly
                value={action}
            />
            <Form.Field
                control={Input}
                label="Status"
                readOnly
                value={status}
            />
            <Form.Field
                control={Input}
                label="Last Updated"
                readOnly
                value={lastUpdated}
            />
        </Form>
    );
}
