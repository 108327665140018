import React from "react";
import {Grid, Tab} from "semantic-ui-react";
import SecureTabPane from "../../SecureTabPane";
import RelaysODTTab from "./RelaysODTTab";
import RelaysODTSQSTab from "./RelaysODTSQSTab";
import RelaysLiveTab from "./RelaysLiveTab";
import RelaysSQSTab from "./RelaysSQSTab";

export default function RelaysAuditMasterDetailView(props) {
    return (
        <Grid>
            <Grid.Column width={16} className="masterContainer">
                <Tab
                    panes={[
                        {
                            menuItem: {
                                key: "vod-relays",
                                content: <label>Relays ODT</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    permissionsRequired={props.permissionsRequired}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    component={
                                        <RelaysODTTab
                                            toast={props.toast}
                                            service={props.service}
                                            module={props.module}
                                            permissions={props.permissions}
                                            user={props.user}
                                        />
                                    }
                                    service={props.service}
                                    module={props.module}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                    loadingPermissions={props.loadingPermissions}
                                />
                        }, {
                            menuItem: {
                                key: "odt-sqs-relays",
                                content: <label>Relays ODT SQS </label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    permissionsRequired={props.permissionsRequired}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    component={
                                        <RelaysODTSQSTab
                                            toast={props.toast}
                                            service={props.service}
                                            module={props.module}
                                            permissions={props.permissions}
                                            user={props.user}
                                        />
                                    }
                                    service={props.service}
                                    module={props.module}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                    loadingPermissions={props.loadingPermissions}
                                />
                        }, {
                            menuItem: {
                                key: "live-relays",
                                content: <label>Relays Live</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    permissionsRequired={props.permissionsRequired}
                                    component={
                                        <RelaysLiveTab
                                            toast={props.toast}
                                            service={props.service}
                                            module={props.module}
                                            permissions={props.permissions}
                                            user={props.user}
                                        />
                                    }
                                    service={props.service}
                                    module={props.module}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                    loadingPermissions={props.loadingPermissions}
                                />
                        }, {
                            menuItem: {
                                key: "sqs-relays",
                                content: <label>Relays SQS</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    permissionsRequired={props.permissionsRequired}
                                    component={
                                        <RelaysSQSTab
                                            toast={props.toast}
                                            service={props.service}
                                            module={props.module}
                                            permissions={props.permissions}
                                            user={props.user}
                                        />
                                    }
                                    service={props.service}
                                    module={props.module}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                    loadingPermissions={props.loadingPermissions}
                                />
                        }
                    ]}
                />
            </Grid.Column>
        </Grid>
    );
}
