import React, {useEffect, useState} from "react";
import {Grid, Input, Dropdown, Button, Form, Container} from "semantic-ui-react";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactTable from "react-table-v6";
import RelaysDataProvider from "../../../Services/RelaysDataProvider";
import RelaysForm from "./RelaysForm";
import ContingentButton from "../../ContingentButton";

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const ONE_WEEK_MS = ONE_DAY_MS * 7;
const STATUS_OPTIONS = [
    {key: "ALL", text: "All", value: "ALL"},
    {key: "QUERY_ODT", text: "Query ODT", value: "QUERY_ODT"},
    {key: "QUERY_ODT_ZOMBIE", text: "Query ODT Zombie", value: "QUERY_ODT_ZOMBIE"},
    {key: "QUERY_ODT_NO_RETRY", text: "Query ODT (No Retry)", value: "QUERY_ODT_NO_RETRY"},
    {key: "SERVE_DESTINATION", text: "Serve Destination", value: "SERVE_DESTINATION"},
    {key: "SERVE_DESTINATION_ZOMBIE", text: "Serve Destination Zombie", value: "SERVE_DESTINATION_ZOMBIE"},
    {key: "SERVE_DESTINATION_NO_RETRY", text: "Serve Destination (No Retry)", value: "SERVE_DESTINATION_NO_RETRY"},
    {key: "PROCESSING_COMPLETE", text: "Process Complete", value: "PROCESSING_COMPLETE"},
    {key: "RESPONSE_TOO_LARGE", text: "Response Too Large", value: "RESPONSE_TOO_LARGE"}
];
const DATE_DISPLAY_OPTIONS = {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"};


export default function RelaysODTSQSTab(props) {
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState("");
    const [selectedKey, setSelectedKey] = useState("");
    const [jobIds, setJobIds] = useState([]);
    const [selectedJobId, setSelectedJobId] = useState("ALL");
    const [start, setStart] = useState(new Date().getTime() - ONE_WEEK_MS);
    const [end, setEnd] = useState(new Date().getTime() + ONE_WEEK_MS);
    const [airingId, setAiringId] = useState("");
    const [status, setStatus] = useState("ALL");
    const [tableWidth, setTableWidth] = useState(16);
    const [loading, setLoading] = useState(true);

    RelaysDataProvider.init({baseURL: process.env.RELAYS_URL});

    useEffect(() => {
        refreshTable();
    }, []);

    useEffect(() => {
        refreshJobs();
    }, []);

    const refreshTable = () => {
        RelaysDataProvider.get(null, RelaysDataProvider.SOURCE.ODTSQS).then(response => {
            if (response.error) {
                console.error(response.error);
                return;
            }
            formatShowsForTable(response);
        }).catch(error => {
            console.error(error);
            formatShowsForTable([]);
        });
    };

    const formatShowsForTable = shows => {
        const jobs = [];
        console.log("(RelaysODTSQSTab.formatShowsForTable) shows: ", shows);
        for (let show of shows) {
            const job = {
                id: show._id,
                jobId: show.job._id,
                action: show.activity.action,
                activityId: show._id,
                airingId: show.activity.odtQueuePayload.AiringId,
                mediaId: show.activity.odtQueuePayload.MediaId,
                status: show.status,
                lastUpdated: new Date(parseInt(show.lastUpdated) * 1000)
            };
            jobs.push(job);
        }

        setJobs(jobs);
        setLoading(false);
    };

    const refreshJobs = () => {
        RelaysDataProvider.getJobs().then(response => {
            const jobIds = [{key: "ALL", text: "All", value: "ALL"}];

            if (response.error) {
                console.error(response.error);
                return;
            }
            for (let job of response) {
                if (job.source === "odt_sqs") {
                    jobIds.push({key: job._id, text: job._id, value: job._id});
                }
            }
            console.log("(RelaysODTSQSTab.refreshJobs) jobs: ", response);
            setJobIds(jobIds);
        }).catch(error => {
            console.error(error);
            setJobIds([]);
        });
    };

    useEffect(() => {
        if (selectedKey && jobs) {
            for (let job of jobs) {
                if (job.id === selectedKey) {
                    setSelectedJob(job);
                    break;
                }
            }
        }
    }, [selectedKey, JSON.stringify(jobs)]);

    const searchClicked = () => {
        const criteria = {
            ...airingId && {airingId},
            ...start && {start : Math.floor(start / 1000)},
            ...end && {end: Math.floor(end / 1000)},
            ...(selectedJobId && selectedJobId !== "ALL") && {job: selectedJobId},
            ...(status && status !== "ALL") && {status}
        };

        setLoading(true);
        setSelectedJob("");
        if (Object.keys(criteria).length > 0) {
            console.log(criteria);
            setTableWidth(16);
            RelaysDataProvider.getByCriteria(criteria, null, RelaysDataProvider.SOURCE.ODTSQS).then(shows => {
                if (shows.error) {
                    formatShowsForTable([]);
                    console.error(shows.error);
                    return;
                }
                formatShowsForTable(shows);
            }).catch(error => {
                console.error(error);
                formatShowsForTable([]);
            });
        } else {
            refreshTable();
        }
    };

    const refreshClicked = () => {
        RelaysDataProvider.getJobById(selectedKey, false).then(response => {
            if (response.error) {
                setJobs([]);
                return;
            }
            let updatedJobs = [];
            for (let show of response) {
                const job = {
                    id: show._id,
                    jobId: show.job._id,
                    action: show.activity.action,
                    activityId: show._id,
                    airingId: show.activity.odtQueuePayload.AiringId,
                    mediaId: show.activity.odtQueuePayload.mediaId,
                    status: show.status,
                    lastUpdated: new Date(parseInt(show.lastUpdated) * 1000)
                }

                updatedJobs.push(job);
            }

            const allJobs = [];
            for (let job of jobs) {
                let jobUpdated = false;
                for (let updatedJob of updatedJobs) {
                    if (job.id === updatedJob.id) {
                        allJobs.push(updatedJob);
                        jobUpdated = true;
                        console.log("(RelaysODTSQSTab.refreshClicked) updated job with id ", job.id, "status: ", job.status);
                        break;
                    }
                }
                if (!jobUpdated) {
                    allJobs.push(job);
                }
            }
            setJobs(allJobs);
        }).catch(error => {
            console.error("(RelaysODTSQSTab.refreshClicked) error: ", error);
            props.toast("Error", "Error reaching Relay Upstream", "error");
        });
    };

    const queryODTZombiesButtonClicked = () =>
        RelaysDataProvider.wakeUpOdtZombies()
            .then(response => props.toast("Success", response.message, "success"))
            .catch(error => props.toast("Error", error));

    const serveDestinationZombiesButtonClicked = () =>
        RelaysDataProvider.wakeUpDestinationZombies()
            .then(response => props.toast("Success", response.message, "success"))
            .catch(error => props.toast("Error", error));

    const reprocessClicked = () => {
        RelaysDataProvider.reprocess(selectedJob.jobId, selectedJob.airingId)
            .then(response => {
                props.toast("Success", "Reprocess started", "success");
                console.log("(RelaysODTSQSTab.reprocessClicked) response: ", response);
                refreshTable();
            }).catch(error => {
            this.props.toast("Error", error.toString(), "error");
        });
    };

    return (
        <Grid>
            <Grid.Column width={tableWidth}>
                <Grid>
                    <Grid.Column width={16}>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field
                                    label="Airing ID"
                                    control={Input}
                                    onChange={(event, {value}) => setAiringId(value)}
                                    value={airingId}
                                />
                                <Form.Field
                                    label="Filter by Job ID"
                                    control={Dropdown}
                                    value={selectedJobId}
                                    onChange={(event, {value}) => setSelectedJobId(value)}
                                    clearable
                                    fluid
                                    selection
                                    options={jobIds}
                                />
                                <Form.Field
                                    label="Filter by Status"
                                    control={Dropdown}
                                    value={status}
                                    onChange={(event, {value}) => setStatus(value)}
                                    clearable
                                    fluid
                                    selection
                                    options={STATUS_OPTIONS}
                                />
                                <Form.Field>
                                    <label>Start Time</label>
                                    <MuiPickersUtilsProvider fluid utils={DateFnsUtils}>
                                        <DateTimePicker
                                            inputVariant="outlined"
                                            fullWidth
                                            id="start"
                                            value={start}
                                            onChange={setStart}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Form.Field>
                                <Form.Field>
                                    <label>End Time</label>
                                    <MuiPickersUtilsProvider fluid utils={DateFnsUtils}>
                                        <DateTimePicker
                                            inputVariant="outlined"
                                            fullWidth
                                            id="end"
                                            value={end}
                                            onChange={setEnd}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button color="blue" onClick={searchClicked} fluid>Search</Button>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <ContingentButton
                                        color="green"
                                        onClick={serveDestinationZombiesButtonClicked}
                                        fluid
                                        service={props.service}
                                        module={props.module}
                                        scope="all"
                                        allPermissions={props.permissions}
                                        user={props.user}
                                    >Awaken Destination Zombies</ContingentButton>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <ContingentButton
                                        color="green"
                                        onClick={queryODTZombiesButtonClicked}
                                        fluid
                                        service={props.service}
                                        module={props.module}
                                        scope="all"
                                        allPermissions={props.permissions}
                                        user={props.user}
                                    >Awaken ODT Zombies</ContingentButton>
                                </Form.Field>
                                <Form.Field />
                                <Form.Field />
                                <Form.Field />
                                <Form.Field />
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid>
                <br />
                <Grid.Row>
                    <div>
                        <ReactTable
                            columns={[
                                {Header: "Job ID", accessor: "jobId"},
                                {Header: "Airing ID", accessor: "airingId"},
                                {Header: "Status", accessor: "status"},
                                {Header: "Last Updated", accessor: "lastUpdated",
                                    Cell: row =>
                                        <span>{new Date(row.value).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>,
                                    filterMethod: (filter, rows) =>
                                        new Date(rows[filter.id].lastUpdated).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}
                            ]}
                            data={jobs}
                            filterable
                            loading={loading}
                            className="-striped -highlight"
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick(event, handleOriginal) {
                                        console.log(rowInfo);
                                        setTableWidth(12);
                                        setSelectedKey(rowInfo.original.id);
                                    },
                                    style: {
                                        background: rowInfo && rowInfo.original && selectedKey === rowInfo.original.id ? 'rgba(65, 83, 175, .5)' : '',
                                    }
                                }
                            }}
                            style={{height: '75vh'}}
                        />

                    </div>
                </Grid.Row>
            </Grid.Column>
            {
                selectedJob ?
                    <Grid.Column width={16 - tableWidth} className="detailsContainer">
                        <RelaysForm
                            selectedJob={selectedJob}
                            refreshClicked={refreshClicked}
                            reprocessClicked={reprocessClicked}
                        />
                    </Grid.Column> : ""
            }
        </Grid>
    );
};
