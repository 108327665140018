import React, {useState, useEffect} from "react";
import {Grid, Segment} from "semantic-ui-react";

export default function RelaySQSSubComponent(props) {
    const [payloadString, setPayloadString] = useState("");

    useEffect(function updatePayloadString() {
        try {
            setPayloadString(JSON.stringify(props.payload, null, 4));
        } catch (e) {
            console.error(`(RelaySQSSubComponent.setPayloadString) error stringifying payload ${props.payload}:\n`, e);
        }
    }, [props.payload]);

    return (
        <Grid>
            <Grid.Column width={16}>
                <Segment>
                    <pre>{payloadString}</pre>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};
