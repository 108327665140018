import React from "react";
import {Form, Button, Input} from "semantic-ui-react";

const DATE_DISPLAY_OPTIONS = {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"};

export default function RelaysLiveForm(props) {

    return (
        <Form>
            <Form.Field>
                <Button onClick={props.refreshClicked}>Refresh</Button>
            </Form.Field>
            <Form.Field
                control={Input}
                label="Activity ID"
                value={props.selectedJob.id}
                readOnly
            />
            <Form.Field
                control={Input}
                label="Job ID"
                value={props.selectedJob.jobId}
                readOnly

            />
            <Form.Field
                control={Input}
                label="Show ID"
                value={props.selectedJob.showId}
                readOnly
            />
            <Form.Field
                control={Input}
                label="Title ID"
                value={props.selectedJob.titleId}
                readOnly
            />
            <Form.Field
                control={Input}
                label="Show Title"
                value={props.selectedJob.showTitle}
                readOnly
            />
            <Form.Field
                control={Input}
                label="Franchise Title"
                value={props.selectedJob.franchiseTitle}
                readOnly
            />
            <Form.Field
                control={Input}
                label="Action"
                value={props.selectedJob.action}
                readOnly
            />
            <Form.Field
                control={Input}
                label="Status"
                value={props.selectedJob.status}
                readOnly
            />
            <Form.Field
                control={Input}
                label="Last Updated"
                value={props.selectedJob.lastUpdated.toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}
                readOnly
            />
            <Form.Field
                control={Input}
                label="Duration"
                value={props.selectedJob.duration}
                readOnly
            />
        </Form>
    );
}
