import React, {useEffect, useState} from "react";
import {Grid, Dropdown, Button, Form} from "semantic-ui-react";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactTable from "react-table-v6";
import RelaysDataProvider from "../../../Services/RelaysDataProvider";
import RelaySQSSubComponent from "./RelaySQSSubComponent";
import ContingentButton from "../../ContingentButton";

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const ONE_WEEK_MS = ONE_DAY_MS * 7;
const STATUS_OPTIONS = [
    {key: "PROCESSING_COMPLETE", text: "Process Complete", value: "PROCESSING_COMPLETE"},
    {key: "SERVE_DESTINATION", text: "Serve Destination", value: "SERVE_DESTINATION"},
    {key: "SERVE_DESTINATION_ZOMBIE", text: "Serve Destination Zombie", value: "SERVE_DESTINATION_ZOMBIE"},
    {key: "SERVE_DESTINATION_NO_RETRY", text: "Serve Destination (No Retry)", value: "SERVE_DESTINATION_NO_RETRY"}
];
const DATE_DISPLAY_OPTIONS = {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"};


export default function RelaysSQSTab(props) {
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState("");
    const [selectedKey, setSelectedKey] = useState("");
    const [jobIds, setJobIds] = useState([]);
    const [selectedJobId, setSelectedJobId] = useState("");
    const [start, setStart] = useState(new Date().getTime() - ONE_WEEK_MS);
    const [end, setEnd] = useState(new Date().getTime() + ONE_WEEK_MS);
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(true);

    RelaysDataProvider.init({baseURL: process.env.RELAYS_URL});

    useEffect(() => {
        refreshTable();
    }, []);

    useEffect(() => {
        refreshJobs();
    }, []);

    const refreshTable = () => {
        RelaysDataProvider.get(null, RelaysDataProvider.SOURCE.SQS).then(shows => {
            for (let show of shows) {
                const encodedPayload = show.activity.apiPayload;
                try {
                    const decodedAPIPayload = atob(encodedPayload);
                    show.activity.apiPayload = JSON.parse(decodedAPIPayload);
                } catch (e) {
                    console.error("RelaysDataProvider.getLive: error base64-decoding apiPayload for show", encodedPayload, e);
                }
            }
            console.log("decoded shows: ", shows);
            return shows;
        }).then(shows => formatShowsForTable(shows));
    }

    const formatShowsForTable = shows => {
        const jobs = [];
        console.log("(RelaysODTTab.formatShowsForTable) shows: ", shows);
        for (let show of shows) {
            const job = {
                id: show._id,
                jobId: show.job._id,
                action: show.activity.action,
                videoId: show.activity.apiPayload.videoId,
                brand: show.activity.apiPayload.brand,
                status: show.status,
                lastUpdated: new Date(parseInt(show.lastUpdated) * 1000)
            };
            for (const key of Object.keys(show.activity)) {
                job[key] = show.activity[key];
            }
            jobs.push(job);
        }

        setJobs(jobs);
        setLoading(false);
    };

    const refreshJobs = () => {
        RelaysDataProvider.getJobs().then(jobs => {
            const jobIds = [];
            for (let job of jobs) {
                if (job.source === "sqs") {
                    jobIds.push({key: job._id, text: job._id, value: job._id});
                }
            }
            console.log("(RelaysODTTab.refreshJobs) jobs: ", jobs);
            setJobIds(jobIds);
        });
    }

    useEffect(() => {
        for (let job of jobs) {
            if (job.id === selectedKey) {
                setSelectedJob(job);
                break;
            }
        }
    }, [selectedKey]);

    const searchClicked = () => {
        const criteria = {};

        if (start) {
            criteria.start = Math.floor(start / 1000);
        }

        if (end) {
            criteria.end = Math.floor(end / 1000);
        }

        if (selectedJobId) {
            criteria.job = selectedJobId;
        }

        if (status) {
            criteria.status = status;
        }

        setLoading(true);
        if (Object.keys(criteria).length > 0) {
            console.log(criteria);
            RelaysDataProvider.getByCriteria(criteria, null, RelaysDataProvider.SOURCE.SQS).then(shows => {
                for (let show of shows) {
                    const encodedPayload = show.activity.apiPayload;
                    try {
                        const decodedAPIPayload = atob(encodedPayload);
                        show.activity.apiPayload = JSON.parse(decodedAPIPayload);
                    } catch (e) {
                        console.error("RelaysDataProvider.getLive: error base64-decoding apiPayload for show", encodedPayload, e);
                    }
                }
                return shows;
            }).then(shows => formatShowsForTable(shows));
        } else {
            refreshTable();
        }
    };

    const refreshClicked = () => {
        RelaysDataProvider.getJobById(selectedKey, null, RelaysDataProvider.SOURCE.SQS).then(response => {
            let updatedJobs = [];
            for (let show of response) {
                const job = {
                    id: show._id,
                    jobId: show.job._id,
                    action: show.activity.action,
                    videoId: show.activity.apiPayload.videoId,
                    brand: show.activity.apiPayload.brand,
                    status: show.status,
                    lastUpdated: new Date(parseInt(show.lastUpdated) * 1000)
                }

                updatedJobs.push(job);
            }

            for (let job of jobs) {
                for (let updatedJob of updatedJobs) {
                    if (job.id === updatedJob.id) {
                        job = updatedJob;
                        console.log("(RelaysSQSTab.refreshClicked) updated job with id ", job.id);
                        break;
                    }
                }
            }
        }).catch(error => {
            console.error("(RelaysSQSTab.refreshClicked) error: ", error);
            props.toast("Error", "Error reaching Relay Upstream", "error");
        });
    };

    const serveDestinationZombiesButtonClicked = () =>
        RelaysDataProvider.wakeUpDestinationZombies()
            .then(response => props.toast("Success", response.message, "success"))
            .catch(error => props.toast("Error", error));

    return (
        <Grid>
            <Grid.Column>
                <Grid>
                    <Grid.Column width={16}>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field
                                    label="Filter by Job ID"
                                    control={Dropdown}
                                    value={selectedJobId}
                                    onChange={(event, {value}) => setSelectedJobId(value)}
                                    clearable
                                    fluid
                                    selection
                                    options={jobIds}
                                />
                                <Form.Field
                                    label="Filter by Status"
                                    control={Dropdown}
                                    value={status}
                                    onChange={(event, {value}) => setStatus(value)}
                                    clearable
                                    fluid
                                    selection
                                    options={STATUS_OPTIONS}
                                />
                                <Form.Field>
                                    <label>Start Time</label>
                                    <MuiPickersUtilsProvider fluid utils={DateFnsUtils}>
                                        <DateTimePicker
                                            inputVariant="outlined"
                                            fullWidth
                                            id="start"
                                            value={start}
                                            onChange={setStart}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Form.Field>
                                <Form.Field>
                                    <label>End Time</label>
                                    <MuiPickersUtilsProvider fluid utils={DateFnsUtils}>
                                        <DateTimePicker
                                            inputVariant="outlined"
                                            fullWidth
                                            id="end"
                                            value={end}
                                            onChange={setEnd}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button color="blue" onClick={searchClicked} fluid>Search</Button>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <ContingentButton
                                        color="green"
                                        onClick={serveDestinationZombiesButtonClicked}
                                        fluid
                                        service={props.service}
                                        module={props.module}
                                        scope="all"
                                        allPermissions={props.permissions}
                                        user={props.user}
                                    >Awaken Destination Zombies</ContingentButton>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid>
                <br />
                <Grid.Row>
                    <div>
                        <ReactTable
                            columns={[
                                {Header: "Job ID", accessor: "jobId"},
                                {Header: "Brand", accessor: "brand"},
                                {Header: "Video ID", accessor: "videoId"},
                                {Header: "Status", accessor: "status"},
                                {accessor: "apiPayload", show: false},
                                {Header: "Last Updated", accessor: "lastUpdated",
                                    Cell: row =>
                                        <span>{new Date(row.value).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>,
                                    filterMethod: (filter, rows) =>
                                        new Date(rows[filter.id].lastUpdated).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}
                            ]}
                            data={jobs}
                            filterable
                            loading={loading}
                            className="-striped -highlight"
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick(event, handleOriginal) {
                                        console.log(rowInfo);
                                        setSelectedKey(rowInfo.original.id);
                                    },
                                    style: {
                                        background: rowInfo && rowInfo.original && selectedKey === rowInfo.original.id ? 'rgba(65, 83, 175, .5)' : '',
                                    }
                                }
                            }}
                            style={{height: '75vh'}}
                            SubComponent={row => <RelaySQSSubComponent payload={row.original.apiPayload}/>}
                        />
                    </div>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
}
